<template>
  <div>
    <div class="w-100 bg-main-color" style="height: 2px;"></div>
    <hot-recommend :hotGoodsList="hotGoodsList"></hot-recommend>
    <div class="w-1200 bg-white mt-2">
      <div class="df-ac text-grey">
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
        <div class="iconfont icon-qianjin"></div>
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
        <div class="iconfont icon-qianjin"></div>
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
      </div>
      <div class="w-100 mt-2 ">
        <div class="screen-box df-ac">
          <div class="pt-2 pl-3 pb-5 pr-2" style="">品牌:</div>
          <div class="flex-1 d-f flex-wrap">
            <div class="border brand-box ml-1 my" v-for="(item,index) in brandList" :key="index">
              <img v-lazy="item.cover" class="brand-img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 w-100 border font-s-1" style="height: 40px;">
        <div>
          <div class="px-2 df-ac" style="height: 40px;">
            <div class="array-style df-ac" @click="comprehensiveClick" :class="{ active: choiceIndex == -1 }">
              <div class="array-name">综合</div>
              <i class="iconfont ml mr-2"></i>
              <div class="border-right array-style" style="width: 1px;height: 25px;"></div>
            </div>
            <div class="array-style df-ac" v-for="(item, index) in choiceList" :key="index"
                 @click="clickChoice(item,index)"
                 :class="{ active: choiceIndex == index }">
              <div class="array-name">{{ item.choiceName }}</div>
              <i class="iconfont ml mr-2 icon-xiangxia" v-show="!item.searchStatus"></i>
              <i class="iconfont ml mr-2 icon-xiangshang" v-show="item.searchStatus"></i>
              <div class="border-right array-style" style="width: 1px;height: 25px;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 w-100">
        <common-goods-list :goodsList="hotGoodsList"></common-goods-list>
      </div>

    </div>
  </div>
</template>

<script>
import hotRecommend from '@/components/productList/HotRecommend.vue'
import commonGoodsList from '@/components/productList/CommonGoodsList.vue'
import { getHotGoods as getHotGoodsUrl, } from '@/api/index'
import axios from 'axios'

export default {
  name: 'NewGoods',
  components: {
    hotRecommend,
    commonGoodsList
  },
  data () {
    return {
      choiceIndex: -1,
      choiceList: [
        { choiceName: '销量', searchStatus: false },
        { choiceName: '价格', searchStatus: false },
      ],
      hotGoodsList: [],
      brandList: [],
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getHotGoodsList()
      this.getBrandList()
    },
    // 获取热门商品
    async getHotGoodsList () {
      const { data: res } = await axios.get(getHotGoodsUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.hotGoodsList = res.data.list
    },
    // 获取品牌列表
    async getBrandList () {
      this.brandList = this.$store.state.commonBrandList
      // 如果商品列表小于0，从actions中获取接口数据，重新渲染品牌列表
      if (this.brandList.length <= 0) {
        await this.$store.dispatch('changeCommonBrandListActions')
        this.brandList = this.$store.state.commonBrandList
      }
    },
    // 顶部销量、价格点击事件
    clickChoice () {

    },
    // 综合点击事件
    comprehensiveClick () {
      // 重置查询条件
      this.categoryQueryInfo.pageSize = 20
      this.categoryQueryInfo.page = 1
      this.categoryQueryInfo.field = ''
      this.categoryQueryInfo.desc = ''
      this.choiceIndex = -1
      this.getCategoryGoodsList()
    },
  }
}
</script>

<style lang="less" scoped>
  .screen-box {
    border: 2px #f3f3f3 dashed;
  }

  .brand-box {
    width: 95px;
    height: 40px;
    .brand-img {
      width: 95px ;
      height: 40px;

    }

    .brand-name {
      position: relative;
      z-index: 1;
    }
  }

  .array-style {
    margin-right: 20px;
    cursor: pointer;

    .iconfont {
      font-size: 12px;
    }
  }

  .active .array-name {
    color: #EF001C;;
  }

  .active .iconfont {
    color: #EF001C;;
  }
</style>
