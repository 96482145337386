<template>
  <div class="w-1200 df-ac mt-2">
    <div class="bg-main-color df-ac-jc hot-recommend">热门推荐</div>
    <div class="flex-1 df-ac-jc" style="height:130px;background-color: #F2F2F2;">
      <div class="df-ac-js px-2 flex-1">
        <div class="bg-white df-ac" style="width: 270px;height: 100px;cursor: pointer"
             v-for="(item,index) in newHotGoodsList" :key="index" @click="toProductDetail(item)">
          <img v-lazy="item.slide_list.img" class="ml-1" style="width: 90px;height: 90px;"/>
          <div class="mx-1">
            <div class="font-s-1 in2line text-grey"> {{ item.title }}</div>
            <div class="font-s-2 font-w  text-main-color">￥{{ item.price }}</div>
            <div class="df-ac-jc font-s-1 bg-main-color" style="width: 60px;height: 20px;">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotRecommend',
  props: {
    hotGoodsList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      console.log(productInfo)
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    }
  },
  computed: {
    newHotGoodsList: function() {
      return this.hotGoodsList.slice(0,4)
    }
  }
}
</script>

<style scoped>
  .hot-recommend {
    width: 35px;
    height: 130px;
    writing-mode: vertical-rl;
    letter-spacing: 8px;
  }

</style>
