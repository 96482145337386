<template>
  <div class="d-f flex-wrap">
    <div class="category-item" style="width: 198px;height: 390px;" v-for="(item,index) in goodsList"
         :key="index" @click="toProductDetail(item)">
      <img v-lazy="item.slide_list.img" class="goods-img px-1 pt-1"/>
      <div class="px-1 mt-1  df-ac-js">
        <div class="text-main-color font-s-5">￥{{item.price}}</div>
        <div class="text-grey font-s-1">已售368件</div>
      </div>
      <div class="px-1 in2line font-s-1 text-dark-grey mt goods-name">{{ item.title }}</div>
      <div class="px-1 py-1 text-grey font-s-1">服装</div>
      <div class="px-1 df-ac">
        <div class="active-state font-s-1 bg-main-color">
          <i class="i-left"></i>热卖<i class="i-right"></i>
        </div>
        <div class="active-state font-s-1 bg-pink">
          <i class="i-left"></i>推荐<i class="i-right"></i>
        </div>
        <div class="active-state font-s-1 bg-wathet">
          <i class="i-left"></i>新品<i class="i-right"></i>
        </div>
      </div>
      <div class="collection-buy mt-1 d-f justify-end pr-2 font-s-1">
        <div class="px-1 py df-ac-jc collection-border"><i class="iconfont icon-shoucang1 mr"></i>收藏</div>
        <div class="px-1 py df-ac-jc collection-border"><i class="iconfont icon-gouwuche mr"></i>加入购物车</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonGoodsList',
  props: {
    goodsList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      console.log(productInfo)
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    }
  },
}
</script>

<style lang="less" scoped>
  .category-item {
    border: 1px solid #ffffff;
    cursor: pointer;

    .goods-img {
      width: 178px;
      height: 178px;
    }
    .goods-name{
      height: 36px;
      overflow: hidden;
    }
    .active-state {
      float: left;
      height: 16px;
      line-height: 16px;
      color: #fff;
      padding: 0 7px;
      position: relative;
      margin-right: 5px;

      i {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 50%;
        top: 4px;
        overflow: hidden;
      }

      .i-left {
        left: -5px;
      }

      .i-right {
        right: -5px;
      }
    }

    .collection-buy {
      .iconfont {
        font-size: 12px;
      }

      .collection-border {
        border: 1px #999 dashed;
      }

      .collection-border:hover {
        border: 1px dashed;
        color: #EF001C;
      }
    }
  }

  .category-item:hover {
    border: 1px solid #f6f6f6;
    box-shadow: 0 0 9px 3px rgba(233, 233, 233, .5);
  }
</style>
